import React from 'react'

import './topNav.scss'

import Logo from '../components/logo'

export default () => {
  return (
    <div className="TopNav">
      <Logo />
      <a className="skip-nav" href="#content">
        Skip to content
      </a>
    </div>
  )
}
