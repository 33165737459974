import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Logo from '../components/logo'
import Navigation from '../components/navigation'

import styled from 'styled-components'

import { useFormik } from 'formik'
import { object, string } from 'yup'

import {
  copyright,
  FooterContainer,
  linkContainer,
  logo,
  navigation,
  newsletter,
} from './footer.module.scss'

export default () => {
  const footerLinkData = useStaticQuery(graphql`
    query footerLinkQuery {
      companyContentfulLink: allContentfulContentContainer(
        filter: { internalTitle: { regex: "/Footer > Company/g" } }
      ) {
        nodes {
          content {
            ... on ContentfulLink {
              id
              url
              externalTitle
              titleAttribute
              onClick
            }
          }
        }
      }
      otherContentfulLink: allContentfulContentContainer(
        filter: { internalTitle: { regex: "/Footer > Other/g" } }
      ) {
        nodes {
          content {
            ... on ContentfulLink {
              id
              url
              externalTitle
              titleAttribute
              onClick
            }
          }
        }
      }
    }
  `)

  const setNavLinks = (node) => ({
    linkText: node.externalTitle,
    linkTitle: node.titleAttribute,
    linkUrl: node.url,
    onClick: node.onClick,
  })

  const companyNavigationLinks = footerLinkData.companyContentfulLink.nodes[0].content.map(
    setNavLinks
  )
  const otherNavigationLinks = footerLinkData.otherContentfulLink.nodes[0].content.map(
    setNavLinks
  )

  return (
    <footer className={FooterContainer}>
      <Logo className={logo} />
      <section className={newsletter}/>
      <section className={linkContainer}>
        <h3>Company</h3>
        <Navigation
          navProps={{ 'aria-label': 'Company', className: navigation }}
          navLinks={companyNavigationLinks}
        />
      </section>

      <section className={linkContainer}>
        <h3>Other</h3>
        <Navigation
          navProps={{ 'aria-label': 'Additional Link', className: navigation }}
          navLinks={otherNavigationLinks}
        />
      </section>
      <section className={copyright}>
        <p>&copy; FLOOF</p>
      </section>
    </footer>
  )
}
