import React, { useState } from 'react'
import { globalHistory } from '@reach/router'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

import ContentfulLink from './link'

import classNames from 'classnames'

import './navigation.scss'

export default ({ navProps, navLinks }) => {
  const isCurrentPage = (link) => {
    const { path, linkUrl } = link
    const { pathname } = globalHistory.location

    return (
      pathname === linkUrl ||
      pathname === `${linkUrl}/` ||
      (path && pathname.indexOf(path) !== -1)
    )
  }

  return (
    <nav role="navigation" {...navProps}>
      <ul className="navigation" role="menubar">
        {navLinks.length > 0
          ? navLinks.map((link, idx) => {
              return (
                <li
                  key={idx * Math.random()}
                  className={classNames({
                    navigationItem: true,
                    active: isCurrentPage(link),
                  })}
                >
                  <ContentfulLink link={link} />
                </li>
              )
            })
          : null}
      </ul>
    </nav>
  )
}
