import React from 'react'

import TopNavigation from '../templates/topNav'
import Footer from '../templates/footer'

const LayoutAuth = ({ children }) => (
  <div className="floof">
    <TopNavigation />
    {children}
    <Footer />
  </div>
)

export default LayoutAuth
