import React from 'react'

import { Link, navigate } from 'gatsby'

import Snackbar from '@material-ui/core/Snackbar'

import LayoutAuth from '../../components/layout-auth'

import { Formik, Form } from 'formik'
import { object, string } from 'yup'

import { TextField, InputAdornment } from '@material-ui/core'
import { StylesProvider } from '@material-ui/core/styles'

import { useAlert, Alert } from '../../hooks/alert'
import { forgotPassword } from '../../services/auth'

import classNames from 'classnames'

import {
  AuthContainer,
  formContainer,
  title,
  subtitle,
  fields,
  field,
  fieldError,
  formLink,
  linkReset,
  submitReset,
} from './auth.module.scss'
import './material-overrides.scss'

const schema = object({
  email: string().email().required(),
})

const ForgotPassword = () => {
  const [alertOpen, alertMessage, openAlert, closeAlert] = useAlert()

  const handleAlertClose = (event, reason) => closeAlert(reason)

  const handleFormSubmit = async (values, { setSubmitting }) => {
    try {
      await forgotPassword(values)

      const { email } = values

      navigate('/auth/forgot-password-confirmation', { state: { email } })
    } catch (err) {
      openAlert(err.message)
    }
  }

  return (
    <LayoutAuth>
      <div className={AuthContainer} id="content">
        <div className={formContainer}>
          <Formik
            initialValues={{ email: '' }}
            validationSchema={schema}
            onSubmit={handleFormSubmit}
          >
            {({ errors, touched, handleChange, handleBlur, isSubmitting }) => (
              <Form>
                <span className={title}>Reset your password</span>
                <span className={subtitle}>
                  Enter your email and we’ll send you a confirmation code
                </span>
                <div className={fields}>
                  <StylesProvider injectFirst>
                    <TextField
                      name="email"
                      variant="filled"
                      className={classNames({
                        [field]:
                          !touched.email || (touched.email && !!!errors.email),
                        [fieldError]: touched.email && errors.email,
                      })}
                      fullWidth
                      label="Your Email"
                      type="text"
                      placeholder="Enter email"
                      helperText={touched.email ? errors.email : ''}
                      error={touched.email && Boolean(errors.email)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </StylesProvider>
                </div>
                <div className={submitReset}>
                  <button type="submit" aria-disabled={isSubmitting}>
                    Send Code
                  </button>
                </div>
                <div className={linkReset}>
                  <span className={formLink}>
                    <Link to="/auth/login">Back to Login</Link>
                  </span>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertOpen}
        message={alertMessage}
        autoHideDuration={5000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {alertMessage}
        </Alert>
      </Snackbar>
    </LayoutAuth>
  )
}

export default ForgotPassword
