import React from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'

const isUrlValid = (url) => {
  var res = url.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  )
  return res !== null
}

export default ({ link, linkProps = {}, children, className }) => {
  const {
    linkClassName,
    linkText,
    linkTitle,
    linkUrl,
    onClick,
  } = link

  if (!linkUrl) return null

  const linkContent = children ? children : linkText

  linkProps.target = isUrlValid(linkUrl) ? '_blank' : null

  const buildContentfulOnClick = (event) => {
    event.stopPropagation()
    event.preventDefault()
    eval(onClick)
  }

  const onClickFn =
    typeof onClick === 'string' ? buildContentfulOnClick : onClick

  return (
    <>
      {isUrlValid(linkUrl) ? (
        <a
          href={linkUrl}
          title={linkTitle}
          className={classNames(linkProps.className, linkClassName, className)}
          onClick={onClickFn}
          {...linkProps}
        >
          {linkContent}
        </a>
      ) : (
        <Link
          to={linkUrl}
          title={linkTitle}
          className={classNames(linkProps.className, linkClassName, className)}
          onClick={onClickFn}
          {...linkProps}
        >
          {linkContent}
        </Link>
      )}
    </>
  )
}
